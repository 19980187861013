@import '../../../styles/propertySets.css';

.root {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: auto;
}

.content {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 24px;
  background-color: var(--matterColorLight);
  /* box-shadow: var(--boxShadowTop); */
}

.avatar {
  /* Layout */
  flex-shrink: 0;
  margin: var(--TopbarMobileMenu_topMargin) 0 0 0;
}

.greeting {
  /* Font */
  /** marketplaceH1FontStyles*/  
 font-family: 'Greycliff';
    font-size: 40px;
    font-weight: 800;
    line-height: 46px;
    letter-spacing: -0.01em;
/** end */
  margin-bottom: 1px;
  margin-top: 16px;
}

.logoutButton {
  /** marketplaceLinkStyles */
 /* Position and dimensions */
    display: inline;
    margin: 0;
    padding: 0;

    /* Borders */
    border: none;

    /* Colors */
    color: var(--marketplaceColor);

    /* Text size should be inherited */
    text-decoration: none;

    &:hover {
      text-decoration: none;
      cursor: pointer;
    }
/** end */
  /* Logout font is smaller and gray since the action is not recommended. */
  /** marketplaceH3FontStyles*/  
font-family: 'Greycliff';
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.5px;
/** end */
  color: var(--matterColorAnti);

  /* Position component */
  width: initial;
  margin: 4px 0 24px 0;
  padding: 0;
}

.inbox {
  /* Font */
  /** marketplaceH1FontStyles*/  
    font-family: 'Greycliff';
    font-size: 32px;
    font-weight: 700;
    line-height: 37px;
    letter-spacing: -1px;
/** end */
  color: var(--marketplaceColor);
  position: relative;

  margin-top: auto;
  margin-bottom: 13px;
}

.navigationLink {
  /* Font */
  /** marketplaceH1FontStyles*/  
    font-family: 'Greycliff';
    font-size: 32px;
    font-weight: 700;
    line-height: 37px;
    letter-spacing: -1px;
/** end */
  color: var(--marketplaceColor);

  /* Layout */
  margin-top: 0;
  margin-bottom: 11px;
}
.currentPageLink {
  color: var(--marketplaceColorDark);
}

.notificationBadge {
  position: absolute;
  top: 4px;
  right: -26px;

  /* This is inside a link - remove underline */
  &:hover {
    text-decoration: none;
  }
}

.createNewListingLink {
  /**marketplaceButtonStyles*/  
  font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
    font-weight: var(--fontWeightSemiBold);
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 24px;

    /* Dimensions */
    display: block;
    width: 100%;
    min-height: 40px;
    margin: 0;

    /* Padding is only for <a> elements where button styles are applied,
		buttons elements should have zero padding */
    padding: 5px 0 5px 0;

    @media (--viewportMedium) {
      padding: 10px 0 10px 0;
    }

    /* Borders */
    border: none;
    border-radius: var(--borderRadius);

    /* Colors */
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);

    /* Text */
    text-align: center;
    text-decoration: none;

    /* Effects */
    transition: all var(--transitionStyleButton);
    cursor: pointer;

    &:hover,
    &:focus {
      outline: none;
      background-color: var(--marketplaceColorDark);
      text-decoration: none;
    }
    &:disabled {
      background-color: var(--matterColorNegative);
      color: var(--matterColorLight);
      cursor: not-allowed;
      box-shadow: none;
    }

    @media (--viewportMedium) {
      min-height: 40px;
    }
/** end */
}

.authenticationGreeting {
  /* Font */
  /** marketplaceH1FontStyles*/  
 font-family: 'Greycliff';
    font-size: 40px;
    font-weight: 800;
    line-height: 46px;
    letter-spacing: -0.01em;
/** end */

  margin-bottom: 24px;
  margin-top: var(--TopbarMobileMenu_topMargin);
}
.authenticationLinks {
  white-space: nowrap;
}

.signupLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}
.loginLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}

.currentPage {
  color: var(--matterColorDark);

  /* black left border */
  border-left: 5px solid black;
  margin-left: -24px;
  padding-left: 19px;
}

.spacer {
  width: 100%;
  height: 124px;
}

.Price {
  color: black;
}

.BagIcon {
  color: white;
}
.ShoppingCart {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}
.ShoppingCartLink {
  height: 34px;
  margin-top: calc((72px - 34px) / 2);
  margin-bottom: calc((72px - 34px) / 2);
  margin-right: 37.5px;
  margin-left: 24px;
  border-radius: 14px;
  background-color: var(--fave-color-purple-s50);
  padding: 8px;
  position: relative;
}

.categoryLink {
  padding:4px 0px;
  font-weight: bold;
  color: black;
}