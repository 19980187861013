@import '../../styles/customMediaQueries.css';

.productsContainer {
  padding: 16px;
  margin-top: 24px;
  background-color: var(--matterColorLight);
  border: 1px solid var(--matterColorNegative);
  border-radius: 14px;

  @media (--viewportSmall) {
    padding: 24px;
  }

  @media (--viewportMedium) {
    margin: 24px 0 24px 0px;
  }

  @media (--viewportLarge) {
    margin: 0px 0 24px 0;
  }
}

.dropdownHeader {
  display: flex;
  justify-content: space-between;
}

.dropdownSwitch {
  font-size: 16px;
  cursor: pointer;
  transition: all 0.4s ease-out;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: var(--fave-color-purple-s500);
    }
  }
}

.itemListContainer {
  overflow: hidden;
  transition: all 0.6s cubic-bezier(1, 0, 1, 0);
  max-height: 9999px;
}

.hide {
  max-height: 0px;
  transition: all 0.6s cubic-bezier(0, 1, 0, 1);
}

.rowDivider {
  margin: 0px;
  padding: 0px;
  height: 1px;
  background-color: var(--fave-color-black-s50);
}
