@import '../../styles/propertySets.css';
@import '../../styles/customMediaQueries.css';
@import '../../styles/faveStyles.css';

/* Order form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  /**marketplaceModalInMobileBaseStyles*/  
    flex-grow: 1;

    /* Create context so the close button can be positioned within it */
    position: relative;

    /* Display and dimensions */
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 0;

    background-color: var(--matterColorLight);
    border-radius: var(--borderRadius);

    @media (--viewportMedium) {
      flex-basis: 480px;
      flex-grow: 0;
      height: 100%;
      padding: 0;
      background-color: transparent;
      border-radius: 0;
    }
/** end */
  height: 100vh;

  @media (--viewportMedium) {
    flex-basis: 576px;
    height: max-content;
    padding: var(--modalPaddingMedium);
    background-color: var(--matterColorLight);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
    display: inline-table;
    width: 100%;
  }

  @media (--viewportLarge) {
    padding: 0;
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.modalHeading {
  margin-top: 89px;
  margin-bottom: 36px;
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.title {
  /* Font */
  /** marketplaceH1FontStyles*/  
 font-family: 'Greycliff';
    font-size: 40px;
    font-weight: 800;
    line-height: 46px;
    letter-spacing: -0.01em;
/** end */
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.price {
  display: none;
  /**fave-copy-large-bold*/  
font-family: Proxima Nova;
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0em;
/** end */
  color: var(--fave-color-black-s500);
  margin: 0 24px;
  margin-bottom: 24px;
  @media (--viewportMedium) {
    margin: 0;
    margin-bottom: 24px;
  }
  @media (--viewportLarge) {
    display: flex;
  }
}

.authorRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.author {
  width: 100%;
  /** fave-copy-default-regular /*
  font-family: Proxima Nova;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
/** end */
  color: var(--fave-color-purple-s500);
  margin-bottom: 16px;
  margin-top: 16px;
}

.link:hover {
  text-decoration: none;
}

.providerAvatar {
  margin-right: 10px;
}

.orderHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-bottom: 0;
  }
}

.orderTitle {
  /* Font */
  color: black;

  margin-top: 0;
  margin-bottom: 4px;

  @media (--viewportLarge) {
  }
}

.orderHelp {
  display: none;
  /**marketplaceH5FontStyles */
    font-family: 'Greycliff';
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
/** end */

  @media (--viewportMedium) {
    color: var(--matterColor);
    display: block;
    margin-top: 0;
  }
}

.bookingForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin: 0 0 84px 0;

  @media (--viewportMedium) {
    margin: 0;
    min-height: 400px;
  }

  @media (--viewportLarge) {
    min-width: 312px;
    min-height: auto;
  }
}

.bookingDatesSubmitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
    position: static;
    bottom: unset;
    background-color: transparent;
  }
}
.bookingDatesSubmitButtonWrapperDisabled {
  background-color: grey;
  background-image: linear-gradient(grey 0%, grey 100%);

  @media (--viewportMedium) {
    background-color: transparent;
  }
}

.openOrderForm {
  /* Ensure that mobile button is over Footer too */
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 18px 24px 18px 16px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
  display: flex;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportMedium) {
    padding: 18px 60px 18px 60px;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  margin-right: 22px;
  padding: 5px 12px;
}

.orderButtonsWrapper {
  margin: auto;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.orderButton {
  height: 40px;
  min-width: 144px;
  flex-grow: 0;
  margin: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.marginLeft {
  margin-left: 4.87px;
}

.orderButtonDisabled {
  background-image: linear-gradient(
    var(--fave-color-black-s75) 0%,
    var(--fave-color-black-s75) 100%
  );
  background-color: var(--fave-color-black-s75);
  color: var(--fave-color-black-s100);
}

.orderButtonDisabled:hover {
  background-color: var(--fave-color-black-s75);
  background-image: linear-gradient(
    var(--fave-color-black-s75) 0%,
    var(--fave-color-black-s75) 100%
  );
  color: var(--fave-color-black-s100);
}

.orderButtonDisabled:active {
  background-color: var(--fave-color-black-s75);
  background-image: linear-gradient(
    var(--fave-color-black-s75) 0%,
    var(--fave-color-black-s75) 100%
  );
  color: var(--fave-color-black-s100);
}

.closedListingButton {
  border-left: 1px solid var(--matterColorNegative);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}

.receipt {
  flex-shrink: 0;
}

.variants {
  padding-top: 20px;
  padding-bottom: 20px;
}

.pillsContainer {
  display: flex;
  align-items: center;
  height: 32px;
}

.goldPill {
  margin-left: 12px;
}

.categoryPill {
  /**fave-copy-xsmall-bold*/  
    font-family: Proxima Nova;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0.3px;
/** end */;
  width: fit-content;
  max-height: 32px;
  padding: 8px;
  color: var(--fave-color-purple-s300);
  border: 1px solid var(--fave-color-purple-s300);
  border-radius: 20px;
}

.listingFandom {
  /** fave-copy-default-regular /*
  font-family: Proxima Nova;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
/** end */
  color: var(--fave-color-black-s200);
  margin: 16px 0px;
}

.listingDescription {
  margin-top:12px;
  margin-bottom:12px;
  
  ul {
    margin: left 12px;
    li {
      list-style-type: disc;
    }
  }
  ol {
    margin-left: 12px;
    li {
      list-style-type: decimal;
    }
  }
}