@import '../../styles/propertySets.css';

.root {
}

.verificiationBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  padding: 15px 32px 23px 32px;
  margin-bottom: 24px;

  border-radius: 4px;
}

@media (--viewportMedium) {
  .verificiationBox {
    flex-wrap: nowrap;
  }
}

.verificiationBoxTextWrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 5px 24px 3px 0px;
  margin-bottom: 16px;
}

@media (--viewportMedium) {
  .verificiationBoxTextWrapper {
    margin-bottom: 0px;
  }
}

.verificationBoxTitle {
  /**--marketplaceH4FontStyles*/  
  font-family: 'Greycliff';
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: -0.4000000059604645px;
/** end */
  font-weight: var(--fontWeightSemiBold);

  align-self: center;

  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.verificationBoxText {
  /**--marketplaceH4FontStyles*/  
  font-family: 'Greycliff';
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: -0.4000000059604645px;
/** end */
  font-weight: var(--fontWeightRegular);
  margin-top: 0;
  margin-bottom: 0;
}

.getVerifiedButton {
  /**marketplaceH5FontStyles */
    font-family: 'Greycliff';
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
/** end */
  font-weight: var(--fontWeightSemiBold);

  border-radius: 4px;
  min-height: 38px;
  width: 300px;
  max-width: 110px;
}

/* Verification required box */
.verficiationNeededBox {
  border: 1px solid var(--attentionColor);
  background: var(--attentionColorLight);
}

/* Verification error box */
.verficiationErrorBox {
  background: var(--failColorLight);
  border: 1px solid var(--failColor);
}

/* Verification success box */
.verficiationSuccessBox {
  background: var(--successColorLight);
  border: 1px solid var(--successColor);
  padding: 8px 24px;
}

.verificationBoxSuccessTextWrapper {
  margin-bottom: 0;
}
.editVerificationButton {
  /**--marketplaceH4FontStyles*/  
  font-family: 'Greycliff';
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: -0.4000000059604645px;
/** end */
  color: var(--matterColor);
  font-weight: var(--fontWeightNormal);
  min-height: 46px;
  margin: 0;
  padding-top: 3px;

  &:hover,
  &:focus {
    outline: none;
    color: var(--matterColorDark);
  }
}

.icon {
  margin-right: 10px;
}

.iconEditPencil {
  stroke: var(--matterColor);
}

.spinner {
  width: 24px;
  height: 24px;
  stroke: var(--successColor);
  stroke-width: 3px;
}
