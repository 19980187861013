@import '../../styles/propertySets.css';
@import '../../styles/customMediaQueries.css';

.root {
  /**marketplaceButtonStyles*/  
  font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
    font-weight: var(--fontWeightSemiBold);
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 24px;

    /* Dimensions */
    display: block;
    width: 100%;
    min-height: 40px;
    margin: 0;

    /* Padding is only for <a> elements where button styles are applied,
		buttons elements should have zero padding */
    padding: 5px 0 5px 0;

    @media (--viewportMedium) {
      padding: 10px 0 10px 0;
    }

    /* Borders */
    border: none;
    border-radius: var(--borderRadius);

    /* Colors */
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);

    /* Text */
    text-align: center;
    text-decoration: none;

    /* Effects */
    transition: all var(--transitionStyleButton);
    cursor: pointer;

    &:hover,
    &:focus {
      outline: none;
      background-color: var(--marketplaceColorDark);
      text-decoration: none;
    }
    &:disabled {
      background-color: var(--matterColorNegative);
      color: var(--matterColorLight);
      cursor: not-allowed;
      box-shadow: none;
    }

    @media (--viewportMedium) {
      min-height: 40px;
    }
/** end */

  /* Clear padding that is set for link elements looking like buttons */
  padding: 0;
}

.primaryButtonRoot {
  /**marketplaceButtonStylesPrimary*/  
    font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
    font-weight: var(--fontWeightSemiBold);
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 24px;

    /* Dimensions */
    display: block;
    width: 100%;
    min-height: 40px;
    margin: 0;

    /* Padding is only for <a> elements where button styles are applied,
		buttons elements should have zero padding */
    padding: 5px 0 5px 0;

    @media (--viewportMedium) {
      padding: 10px 0 10px 0;
    }

    /* Borders */
    border: none;
    border-radius: var(--borderRadius);

    /* Colors */
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);

    /* Text */
    text-align: center;
    text-decoration: none;

    /* Effects */
    transition: all var(--transitionStyleButton);
    cursor: pointer;

    &:hover,
    &:focus {
      outline: none;
      background-color: var(--marketplaceColorDark);
      text-decoration: none;
    }
    &:disabled {
      background-color: var(--matterColorNegative);
      color: var(--matterColorLight);
      cursor: not-allowed;
      box-shadow: none;
    }

    @media (--viewportMedium) {
      min-height: 40px;
    }
    
    background-color: black;
    background-image: none;
    color: var(--color-white-pure);

    &:hover,
    &:focus {
      background-image: none;
      background-color: black;
    }
    &:disabled {
      background-image: none;
      background-color: var(--fave-color-border);
      color: var(--color-white-pure);
    }
/** end */;

  /* Clear padding that is set for link elements looking like buttons */
  padding: 0;
}

.secondaryButtonRoot {
  /**marketplaceButtonStylesPrimary*/  
  
    font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
    font-weight: var(--fontWeightSemiBold);
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 24px;

    /* Dimensions */
    display: block;
    width: 100%;
    min-height: 40px;
    margin: 0;

    /* Padding is only for <a> elements where button styles are applied,
		buttons elements should have zero padding */
    padding: 5px 0 5px 0;

    @media (--viewportMedium) {
      padding: 10px 0 10px 0;
    }

    /* Borders */
    border: none;
    border-radius: var(--borderRadius);

    /* Colors */
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);

    /* Text */
    text-align: center;
    text-decoration: none;

    /* Effects */
    transition: all var(--transitionStyleButton);
    cursor: pointer;

    &:hover,
    &:focus {
      outline: none;
      background-color: var(--marketplaceColorDark);
      text-decoration: none;
    }
    &:disabled {
      background-color: var(--matterColorNegative);
      color: var(--matterColorLight);
      cursor: not-allowed;
      box-shadow: none;
    }

    @media (--viewportMedium) {
      min-height: 40px;
    }

    background-color: var(--fave-color-input);
    color: var(--fave-color-primary-default);

    &:hover,
    &:focus {
      background-color: var(--fave-color-border);
     
      text-decoration: none;
    }
    &:disabled {
      background-color: var(--matterColorNegative);
      color: var(--matterColorLight);
    }
/** end */;

  /* We must lift up the text from the center since it looks better with
   the button border. Also clears the top padding set for link
   elements looking like buttons */
  padding: 0;
}

.inlineTextButtonRoot {
  /** marketplaceLinkStyles */
 /* Position and dimensions */
    display: inline;
    margin: 0;
    padding: 0;

    /* Borders */
    border: none;

    /* Colors */
    color: var(--marketplaceColor);

    /* Text size should be inherited */
    text-decoration: none;

    &:hover {
      text-decoration: none;
      cursor: pointer;
    }
/** end */
}

.primaryButton {
  /* Class handle for primary button state styles */
}
.secondaryButton {
  /* Class handle for secondary button state styles */
}

.inProgress {
  padding: 0;

  &:disabled {
    background-color: var(--marketplaceColor);
  }
}

.primaryButton.inProgress:disabled {
  background-color: var(--successColor);
}

.secondaryButton.inProgress:disabled {
  background-color: var(--matterColorLight);
}

.ready {
  padding: 0;

  &:disabled {
    background-color: var(--marketplaceColor);
  }
}

.primaryButton.ready:disabled {
  background-color: var(--successColor);
}

.secondaryButton.ready:disabled {
  background-color: var(--matterColorLight);
}

.spinner {
  width: 28px;
  height: 28px;
  stroke: var(--matterColorLight);
  stroke-width: 3px;
}

.checkmark {
  width: 24px;
  height: 24px;
  stroke: var(--matterColorLight);

  /* Animation */
  animation-name: checkmarkAnimation;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
}

@keyframes checkmarkAnimation {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.secondaryButton .spinner {
  stroke: var(--matterColorAnti);
}

.secondaryButton .checkmark {
  stroke: var(--matterColorAnti);
}

/* Social logins && SSO buttons */

.socialButtonRoot {
  /**marketplaceButtonStylesPrimary*/  
    font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
    font-weight: var(--fontWeightSemiBold);
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 24px;

    /* Dimensions */
    display: block;
    width: 100%;
    min-height: 40px;
    margin: 0;

    /* Padding is only for <a> elements where button styles are applied,
		buttons elements should have zero padding */
    padding: 5px 0 5px 0;

    @media (--viewportMedium) {
      padding: 10px 0 10px 0;
    }

    /* Borders */
    border: none;
    border-radius: var(--borderRadius);

    /* Colors */
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);

    /* Text */
    text-align: center;
    text-decoration: none;

    /* Effects */
    transition: all var(--transitionStyleButton);
    cursor: pointer;

    &:hover,
    &:focus {
      outline: none;
      background-color: var(--marketplaceColorDark);
      text-decoration: none;
    }
    &:disabled {
      background-color: var(--matterColorNegative);
      color: var(--matterColorLight);
      cursor: not-allowed;
      box-shadow: none;
    }

    @media (--viewportMedium) {
      min-height: 40px;
    }
    
    background-color: black;
    background-image: none;
    color: var(--color-white-pure);

    &:hover,
    &:focus {
      background-image: none;
      background-color: black;
    }
    &:disabled {
      background-image: none;
      background-color: var(--fave-color-border);
      color: var(--color-white-pure);
    }
/** end */;

  padding: 0;

  /* We need to add this to position the icon inside button */
  position: relative;
}

.socialButton {
}
