.root {
  display: flex;
  justify-content: flex-start;
  height: 60px;
  align-items: flex-start;
  padding: 0px 10px 0 10px;
  box-shadow: 0px 1px 0px #f3eeff;

  @media (--viewportMedium) {
    display: flex;
    justify-content: flex-start;
    height: 60px;
    align-items: flex-start;
    padding: 0px 24px 0 24px;
  }

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

.tab {
  display: flex;
  align-items: flex-end;
  height: 100%;
  margin-left: 8px;

  &:first-child {
    margin-left: 0;
  }
}
