.fieldRoot {
}
.fieldRoot label {
  z-index: 1;
}
.pickerSuccess {
  & :global(.DateInput_input) {
    border-color: var(--fave-color-primary-s500);
  }
}

.pickerError {
  & :global(.DateInput_input) {
    border-color: var(--failColor);
  }
}

/**
 * Mobile margins mean that labels, inputs (incl separate borders) get margins,
 * but calendar popup doesn't.
 */
.mobileMargins {
  width: calc(100% - 48px);
  margin: 0 24px;

  @media (--viewportMedium) {
    width: 100%;
    margin: 0;
  }
}
