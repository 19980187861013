@import '../../styles/propertySets.css';
@import '../../styles/customMediaQueries.css';

.container {
  display: flex;
  gap: 10px;
}

.optionContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 12px 0px;
}

.radioButtonWrapper {
}

.radioButton {
  appearance: none;
  position: relative;
  height: 20px;
  width: 20px;
  border-radius: 6px;
  background: var(--fave-color-black-s50);
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 0.25s ease-out;
}

.radioButton:hover,
.radioButton:checked {
  background: linear-gradient(83.64deg, black 10.03%, black 89.97%);
}

.radioButton:focus {
  border: 1px solid var(--fave-color-purple-s700);
}

.radioButton:before {
  content: '';
  background-image: url('./checkmark.svg');
  background-position: center;
  position: absolute;
  right: 4px;
  top: 4px;
  bottom: 4px;
  left: 4px;
  color: white;
  opacity: 0;
  transition: all 0.25s ease-out;
}

.radioButton:checked:before {
  opacity: 1;
}

.optionLabel {
  position: static;
  margin-left: 8px;
  /** fave-copy-small-regular */  
 font-family: Proxima Nova;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.2px;
/** end */

  @media (--viewportMedium) {
    /** fave-copy-default-regular */
  font-family: Proxima Nova;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
/** end */
  }
}
