@import '../../styles/propertySets.css';

.root {
  & p {
    /** fave-copy-default-regular */
  font-family: Proxima Nova;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
/** end */
  }

  & h2 {
    /* Adjust heading margins to work with the reduced body font size */
    margin: 29px 0 13px 0;

    @media (--viewportMedium) {
      margin: 32px 0 0 0;
    }
  }

  & ol {
    margin-left: 24px;
    list-style-type: disc;
    & li {
      margin: 6px 0px;
      line-height: 1.25;
    }
  }

  & .lastUpdated {
    /**marketplaceBodyFontStyles*/
/** fave-copy-default-regular */
  font-family: Proxima Nova;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
/** end */
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 12px;
    margin-bottom: 12px;

    @media (--viewportMedium) {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 16px;
      margin-bottom: 16px;
    }
/** end */
    display: block;
    width: 100%;
    margin: 0;
    padding: 8px 16px 8px 24px;
    background-color: var(--color-white-pure);

    /* Unset user agent styles */
    appearance: none;

    /* Borders */
    border: 1px solid var(--fave-color-black-s50);
    border-radius: var(--borderRadius);

    /* Background */
    background-image: url('data:image/svg+xml;utf8,<svg width="14" height="9" viewBox="0 0 14 9" xmlns="http://www.w3.org/2000/svg"><path d="M6.53 7.472c.26.26.68.26.94 0l5.335-5.333c.26-.263.26-.684 0-.944-.26-.26-.683-.26-.943 0L7 6.056l-4.862-4.86c-.26-.26-.683-.26-.943 0-.26.26-.26.68 0 .943L6.53 7.47z" stroke="%234A4A4A" fill="%234A4A4A" fill-rule="evenodd"/></svg>');
    background-size: 16px 24px;
    background-position: bottom 50% right 11px;

    /* Effects */

    cursor: pointer;
    transition: border-color var(--transitionStyle);

    &:hover,
    &:focus {
      border-color: var(--fave-color-primary-s500);
      outline: none;
    }

    &:disabled {
      color: var(--fave-color-black-s100);
      border-color: var(--fave-color-black-s50);
      padding: 8px 16px 8px 16px;

      cursor: not-allowed;
    }

    @media (--viewportMedium) {
      padding: 8px 16px 8px 16px;
    }
    /* No margins for default font */

    @media (--viewportMedium) {
      font-size: 16px;
      line-height: 32px;
    }
/** end */
    margin-top: 0;
    margin-bottom: 55px;

    @media (--viewportMedium) {
      margin-top: 0;
      margin-bottom: 54px;
    }
  }
}
