@import '../../styles/propertySets.css';
@import '../../styles/customMediaQueries.css';

/* Content is visible as modal layer */
.isOpen {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;

  /* scrollLayer is the .root for the Modal */
  & .scrollLayer {
    /**marketplaceModalRootStyles*/  
  /* Page is using flex: AuthenticationPage's .root takes all available space */
    flex-grow: 1;

    /* AuthenticationPage's root uses flexbox */
    display: flex;

    @media (--viewportMedium) {
      justify-content: center;
      align-items: flex-start;
    }
/** end */

    /* Add default background color to avoid bouncing scroll showing the
   page contents from behind the modal. */
    background-color: var(--matterColorLight);

    /* Additional styles for the modal window, dimming the background and positioning the modal */
    min-height: 100vh;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    @media (--viewportMedium) {
      padding: 0;
      background-color: rgba(0, 0, 0, 0.4);
      background-image: none;
    }
  }
  & .container {
    /**marketplaceModalBaseStyles*/  
     flex-grow: 1;

    /* Create context so the close button can be positioned within it */
    position: relative;

    /* Display and dimensions */
    display: flex;
    flex-direction: column;
    padding: var(--modalPadding);

    background-color: var(--matterColorLight);
    border-radius: var(--borderRadius);
    border-bottom: none;

    @media (--viewportMedium) {
      flex-basis: 480px;
      flex-grow: 0;
      /* min-height: 568px; */
      padding: var(--modalPaddingMedium);
      margin-top: 12.5vh;
      margin-bottom: 12.5vh;
    }
/** end */
    min-height: 100vh;
    height: 100%;

    @media (--viewportMedium) {
      flex-basis: 576px;
      min-height: auto;
      height: auto;
    }
  }
}

.isOpenMobileNonFullScreen {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  min-height: 100vh;

  /* scrollLayer is the .root for the Modal */
  & .scrollLayer {
    /**marketplaceModalRootStylesNoFullScreen*/  
 /* Page is using flex: AuthenticationPage's .root takes all available space */

    /* AuthenticationPage's root uses flexbox */
    display: flex;

    @media (--viewportMedium) {
      justify-content: center;
      align-items: flex-start;
    }
/** end */
    padding: 0;
    background-color: rgba(0, 0, 0, 0.4);
    background-image: none;
    height: 100vh;
    align-items: center;
    justify-content: center;
  }

  & .container {
    /** marketplaceModalBaseStylesNoFullScreen/*
   /* flex-basis: 293px; */
    margin: 20px;

    /* Display and dimensions */
    display: flex;
    flex-direction: column;
    padding: var(--modalPadding);

    background-color: var(--matterColorLight);
    border-radius: var(--borderRadius);

    @media (--viewportMedium) {
      flex-basis: 480px;
      flex-grow: 0;
      /* min-height: 568px; */
      padding: var(--modalPaddingMedium);
      margin-top: 12.5vh;
      margin-bottom: 12.5vh;
    }
/** end */
    position: relative;

    @media (--viewportMedium) {
      flex-basis: 576px;
      min-height: auto;
      height: auto;
    }
  }
}
.content {
}

/* Content is explicitly hidden (this default can be overridden with passed-in class) */
/* The use case for having both .isOpen and .isClosed is ModalInMobile use case       */
/* where desktop layout should not get any styling from Modal component.              */
.isClosed {
  display: none;
}

.close {
  /**marketplaceModalCloseStyles*/  
   /* Position inside modal */
    position: fixed;
    top: 0;
    right: 0;

    /* Some content (like map) is positioning itself on top of close button without z-index handling */
    z-index: calc(var(--zIndexModal) + 1);

    /* Safari didn't know how to baseline-align svg icon and text */
    display: flex;
    align-items: flex-start;
    width: auto;

    /* Sizing (touch area) */
    padding: 24px;
    margin: 0;
    border: 0;

    @media (--viewportMedium) {
      padding: 27px 30px;
      position: absolute;
    }

    /* Colors */
    background-color: transparent;
    color: var(--matterColor);

    cursor: pointer;

    &:enabled:hover {
      background-color: transparent;
      color: var(--matterColorDark);
      box-shadow: none;
      text-decoration: none;
    }
    &:enabled:active {
      background-color: transparent;
      color: var(--matterColorDark);
    }
    &:disabled {
      background-color: transparent;
    }
/** end */
}
.closeNoFullScreen {
  /**marketplaceModalCloseStylesNoFullScreen*/  
  /* Position inside modal */

    top: 0;
    right: 0;

    /* Some content (like map) is positioning itself on top of close button without z-index handling */
    z-index: calc(var(--zIndexModal) + 1);

    /* Safari didn't know how to baseline-align svg icon and text */
    display: flex;
    align-items: flex-start;
    width: auto;

    /* Sizing (touch area) */
    padding: 24px;
    margin: 0;
    border: 0;

    /* @media (--viewportMedium) { */
    padding: 27px 30px;
    position: absolute;
    /* } */

    /* Colors */
    background-color: transparent;
    color: var(--matterColor);

    cursor: pointer;

    &:enabled:hover {
      background-color: transparent;
      color: var(--matterColorDark);
      box-shadow: none;
      text-decoration: none;
    }

    &:enabled:active {
      background-color: transparent;
      color: var(--matterColorDark);
    }

    &:disabled {
      background-color: transparent;
    }
/** end */
}

.closeText {
  /**marketplaceModalCloseText*/  
   /* Font */
    /**marketplaceH6FontStyles*/  
 font-weight: var(--fontWeightBold);
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 10px;
    margin-bottom: 8px;

    @media (--viewportMedium) {
      line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 10px;
      margin-bottom: 6px;
    }
/** end */
    margin: 0;

    @media (--viewportMedium) {
      margin: 0;
    }
/** end */
}

.closeTextNoFullScreen {
  /** marketplaceModalCloseTextNoFullScreen /*
   /* Font */
    /**marketplaceH6FontStyles*/  
 font-weight: var(--fontWeightBold);
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 10px;
    margin-bottom: 8px;

    @media (--viewportMedium) {
      line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 10px;
      margin-bottom: 6px;
    }
/** end */
    display: none;

    @media (--viewportMedium) {
      display: default;
      margin: 0;
    }
/** end */
}
.closeIcon {
  /**marketplaceModalCloseIcon*/  
     display: inline-block;
    margin-left: 8px;

    /* Move X icon vertically to align it with the close text. */
    margin-top: 3px;

    box-sizing: content-box;

    @media (--viewportMedium) {
      margin-top: 2px;
    }
/** end */
}

.closeLight {
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  &:enabled:hover,
  &:enabled:active {
    color: var(--matterColorLight);
  }
}

.focusedDiv {
  &:focus {
    outline: none;
  }
}
