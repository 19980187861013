@import '../../../styles/propertySets.css';

/* Desktop */
.root {
  /* Size */
  width: 100%;
  height: var(--topbarHeightDesktop);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px 0 0;

  /* fill */
  background-color: white;

  @media (--viewportLarge) {
    padding: 0 24px 0 0;
  }
}

.Price {
  color: black;
}

.BagIcon {
  color: white;
}

.ShoppingCart {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.ShoppingCartLink {
  height: 34px;
  margin-top: calc((72px - 34px) / 2);
  margin-bottom: calc((72px - 34px) / 2);

  margin-left: 12px;
  border-radius: 14px;

  position: relative;
}

.ShoppingCartLink:hover {
  text-decoration: none;
}

/* logo */
.logoLink {
  /** topardesktoplinkhover */
  border-bottom: 0px solid;
    transition: var(--transitionStyleButton);

    &:hover {

      text-decoration: none;
    }
/**end*/
  flex-shrink: 0;
  padding: 8px;

  @media (--viewportLarge) {
    padding: 8px;
  }
}

.logo {
  /* If height is increased, change link paddings accordingly */
  height: 45px;

  /* Good aspect ratio should be less than 160/27 (width/height) */
  /* NOTE: extremely wide logos, need special handling. */
  width: auto;
  max-width: 220px;
  object-fit: contain;
  object-position: left center;
}

/* Search */
.searchLink {
  flex-grow: 2;
  margin-right: 24px;
  max-width: 550px;

  @media (--viewportLarge) {
    padding-left: 0;
  }
}

.separator {
  flex-grow: 0.5;
}

.search {
  /** fave-copy-default-regular /*
  font-family: Proxima Nova;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
/** end */
display: inline-block;
margin: 28px 0;
text-decoration: inherit;

@media (--viewportMedium) {
  margin: 28px 0;
}
  color: var(--color-white-pure);
}

/* Create listing (CTA for providers) */
.createListingLink {
  /** topardesktoplinkhover */
  border-bottom: 0px solid;
    transition: var(--transitionStyleButton);

    &:hover {

      text-decoration: none;
    }
/**end*/
  /** fave-copy-default-regular /*
  font-family: Proxima Nova;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
/** end */

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;
  color: var(--fave-color-black-s400);
  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--fave-color-primary-default);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.createListing {
  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;

  @media (--viewportMedium) {
    margin: 28px 0;
  }
}

/* Inbox */
.inboxLink {
  /** topardesktoplinkhover */
  border-bottom: 0px solid;
    transition: var(--transitionStyleButton);

    &:hover {

      text-decoration: none;
    }
/**end*/
  /**fave-copy-default-bold*/  
  font-family: Proxima Nova;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
/** end */
  height: 100%;
  padding: 0 12px 0 12px;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--fave-color-black-s400);

  &:hover {
    color: var(--fave-color-primary-default);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.inbox {
  /** topardesktoplinkhover */
  border-bottom: 0px solid;
    transition: var(--transitionStyleButton);

    &:hover {

      text-decoration: none;
    }
/**end*/
  /* @apply --TopbarDesktop_label; */
  position: relative;
}

.notificationDot {
  /* Dimensions */
  width: 7px;
  height: 7px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: calc(50% - 23px / 2 - 7.5px);
  right: -9px;

  /* Style: red dot */
  background-color: var(--failColor);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

.notificationDotSmall {
  /* Dimensions */
  width: 7px;
  height: 7px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: -5%;
  right: -9px;

  /* Style: red dot */
  background-color: var(--failColor);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */
.profileMenuLabel {
  /** topardesktoplinkhover */
  border-bottom: 0px solid;
    transition: var(--transitionStyleButton);

    &:hover {

      text-decoration: none;
    }
/**end*/
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  margin-right: 37.5px;
  padding: 0 12px 0 12px;

  &:active {
    border-bottom: 0;
  }
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.avatar {
  margin: 16px 0;
}

.profileMenuContent {
  min-width: 276px;
  padding-top: 20px;
}
.shopMenuContent {
  min-width: 276px;
  padding-top: 20px;
  padding-bottom: 20px;
}

/* Authentication */
.signupLink {
  /** topardesktoplinkhover */
  border-bottom: 0px solid;
    transition: var(--transitionStyleButton);

    &:hover {

      text-decoration: none;
    }
/**end*/
  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;

  color: var(--fave-color-black-s400);

  &:hover {
    color: var(--fave-color-primary-default);
    text-decoration: none;
  }
}

.loginLink {
  /** topardesktoplinkhover */
  border-bottom: 0px solid;
    transition: var(--transitionStyleButton);

    &:hover {

      text-decoration: none;
    }
/**end*/
  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;

  /**fave-copy-default-bold*/  
  font-family: Proxima Nova;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
/** end */
  color: #632dff;

}

.signup,
.login {
  /**fave-copy-default-bold*/  
  font-family: Proxima Nova;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
/** end */
display: inline-block;
margin: 28px 0;
text-decoration: inherit;

@media (--viewportMedium) {
  margin: 28px 0;
}
}

.profileSettingsLink,
.yourListingsLink {
  /**fave-copy-default-bold*/  
  font-family: Proxima Nova;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
/** end */
  position: relative;
  display: block;

  /* Dimensions */
  position: relative;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  color: var(--matterColor);
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--fave-color-primary-default);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.logoutButton {
  /** marketplaceLinkStyles */
 /* Position and dimensions */
    display: inline;
    margin: 0;
    padding: 0;

    /* Borders */
    border: none;

    /* Colors */
    color: var(--marketplaceColor);

    /* Text size should be inherited */
    text-decoration: none;

    &:hover {
      text-decoration: none;
      cursor: pointer;
    }
/** end */
  /* Font is specific to this component */
  /**fave-copy-default-bold*/  
  font-family: Proxima Nova;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
/** end */

  /* Dimensions */
  position: relative;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 0px 24px;
  padding-bottom:20px;

  /* Layout details */
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);

  color: var(--matterColorDark);
  &:hover {
    color: #632dff;
    text-decoration: none;
  }

}

.currentPage {
   color: #632dff;
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--matterColorDark);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--matterColorDark);
      text-decoration: none;
    }
  }
}

.categoryLinksContainer {
  display: flex;
  
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
}

.categoryLink {
  padding:0px 8px;
  font-weight: bold;
  color: black;
}
