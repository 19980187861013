@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.unfullfilled > .dot {
  padding: 4px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: var(--fave-color-primary-default);
  margin: 4px;
}

.fullfilled > .dot {
  padding: 4px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: var(--fave-color-primary-default);
  margin: 4px;
}

.canceled > .dot {
  padding: 4px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: var(--fave-color-error-s900);
  margin: 4px;
}

.fullfillmentRow {
  /**fave-copy-xsmall-regular*/  
font-family: Proxima Nova;
    font-size: 12px;
    font-weight: 200;
    line-height: 15px;
    letter-spacing: 0.3px;
/** end */
  line-height: 18px;
  font-weight: var(--fontWeightMedium);
  letter-spacing: normal;

  margin-top: 6px;
  margin-bottom: 0px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: normal;
}
