@import '../../../styles/propertySets.css';

.buttonStyles {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /** fave-copy-small-regular */  
   font-family: Proxima Nova;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.2px;
/** end */
  letter-spacing: 0.2px;
  border-radius: var(--borderRadius);
  background-color: black;
  color: var(--fave-color-white);
  transition: filter 0.1s linear;
  text-decoration: none;
  padding-top: 11px;
  padding-bottom: 11px;

  &:hover,
  &:focus {
    filter: contrast(1.4);
  }
}
