@import '../../styles/propertySets.css';
@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.nowrap {
  white-space: nowrap;
}

.lineItem {
  /**--marketplaceH4FontStyles*/  
  font-family: 'Greycliff';
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: -0.4000000059604645px;
/** end */
  margin: 0;

  @media (--viewportMedium) {
    padding-top: 7px;
    padding-bottom: 1px;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bookingPeriod {
  flex: 1 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 6px;

  @media (--viewportMedium) {
    padding-bottom: 8px;
  }
}

.bookingPeriodSectionRigth {
  text-align: right;
}

.dayLabel {
  /**marketplaceH5FontStyles */
    font-family: 'Greycliff';
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
/** end */
  margin: 0;
  color: var(--matterColorAnti);
  line-height: 24px;
  padding-top: 2px;
  padding-bottom: 4px;

  @media (--viewportMedium) {
    padding-top: 1px;
    padding-bottom: 7px;
  }
}

.dayInfo {
  /** marketplaceButtonFontStyles */
    font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
    font-weight: var(--fontWeightSemiBold);
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 24px;
/** end */
}

.subTotalLineItem {
  /**fave-copy-default-bold*/  
  font-family: Proxima Nova;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
/** end */
  margin: 0;
  padding: 5px 0 1px 0;
  color: var(--fave-color-black-s200);

  @media (--viewportMedium) {
    padding: 7px 0 1px 0;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.subTotalValue {
  color: var(--fave-color-black-s200);
  /**fave-copy-default-bold*/  
  font-family: Proxima Nova;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
/** end */
}

.lineItemTotal {
  /**fave-copy-default-bold*/  
  font-family: Proxima Nova;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
/** end */
  margin: 0;
  padding-top: 6px;
  padding-bottom: 6px;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  @media (--viewportMedium) {
    padding-top: 7px;
    padding-bottom: 1px;
  }
}
.itemLabel {
  /** fave-copy-default-regular /*
  font-family: Proxima Nova;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
/** end */
  color: var(--fave-color-black-s300);
  flex: 1;
}

.itemValue {
  /** fave-copy-default-regular /*
  font-family: Proxima Nova;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
/** end */
  color: var(--fave-color-black-s300);
  margin: 0 0 0 10px;
}

.totalDivider {
  /* dimensions */
  width: 100%;
  height: 1px;
  margin: 12px 0 5px 0;

  border: none;
  background-color: var(--matterColorNegative);

  @media (--viewportMedium) {
    margin: 7px 0 0px 0;
  }
}

.totalLabel {
  /**fave-copy-default-bold*/  
  font-family: Proxima Nova;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
/** end */
}

.totalPrice {
  /**fave-copy-default-bold*/  
  font-family: Proxima Nova;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
/** end */
  margin: 0 0 0 10px;
  padding-top: 0px;
}

.feeInfo {
  /**marketplaceTinyFontStyles*/  
   font-family: var(--fontFamily);
    font-weight: var(--fontWeightRegular);
    font-size: 13px;
    line-height: 18px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 9.5px;
    margin-bottom: 8.5px;
    -webkit-font-smoothing: subpixel-antialiased;

    @media (--viewportMedium) {
      line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 10.5px;
      margin-bottom: 13.5px;
    }
/** end */
  flex-shrink: 0;
  margin: 0;
  color: var(--matterColorAnti);
  padding-top: 4px;
  padding-bottom: 14px;

  @media (--viewportMedium) {
    padding-top: 11px;
    padding-bottom: 21px;
  }
}
