@import '../../styles/propertySets.css';

.root {
  margin: 0;
}

.twoColumns {
  @media (--viewportMedium) {
    column-count: 2;
  }
}

.item {
  display: flex;
  align-items: center;
  padding: 3px 0;

  @media (--viewportMedium) {
    padding: 4px 0;
  }
}

.checkIcon {
}

.hidden {
  visibility: hidden;
}

.marketplaceFill {
  fill: var(--marketplaceColor);
}

.iconWrapper {
  align-self: baseline;
  margin-right: 8px;

  /* This follows line-height */
  height: 24px;
}

.labelWrapper {
  display: inline-block;
}

.selectedLabel,
.notSelectedLabel {
  /**marketplaceBodyFontStyles*/
/** fave-copy-default-regular */
  font-family: Proxima Nova;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
/** end */
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 12px;
    margin-bottom: 12px;

    @media (--viewportMedium) {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 16px;
      margin-bottom: 16px;
    }
/** end */
    display: block;
    width: 100%;
    margin: 0;
    padding: 8px 16px 8px 24px;
    background-color: var(--color-white-pure);

    /* Unset user agent styles */
    appearance: none;

    /* Borders */
    border: 1px solid var(--fave-color-black-s50);
    border-radius: var(--borderRadius);

    /* Background */
    background-image: url('data:image/svg+xml;utf8,<svg width="14" height="9" viewBox="0 0 14 9" xmlns="http://www.w3.org/2000/svg"><path d="M6.53 7.472c.26.26.68.26.94 0l5.335-5.333c.26-.263.26-.684 0-.944-.26-.26-.683-.26-.943 0L7 6.056l-4.862-4.86c-.26-.26-.683-.26-.943 0-.26.26-.26.68 0 .943L6.53 7.47z" stroke="%234A4A4A" fill="%234A4A4A" fill-rule="evenodd"/></svg>');
    background-size: 16px 24px;
    background-position: bottom 50% right 11px;

    /* Effects */

    cursor: pointer;
    transition: border-color var(--transitionStyle);

    &:hover,
    &:focus {
      border-color: var(--fave-color-primary-s500);
      outline: none;
    }

    &:disabled {
      color: var(--fave-color-black-s100);
      border-color: var(--fave-color-black-s50);
      padding: 8px 16px 8px 16px;

      cursor: not-allowed;
    }

    @media (--viewportMedium) {
      padding: 8px 16px 8px 16px;
    }
    /* No margins for default font */

    @media (--viewportMedium) {
      font-size: 16px;
      line-height: 32px;
    }
/** end */
  line-height: 24px;
  margin: 0;
}

.selectedLabel {
  font-weight: var(--fontWeightSemiBold);
}

.notSelectedLabel {
  color: var(--matterColorNegative);
  position: relative;

  /* line-through */
  background-image: linear-gradient(
    transparent 10px,
    var(--matterColorNegative) 10px,
    var(--matterColorNegative) 11px,
    transparent 11px
  );

  @media (--viewportMedium) {
    background-image: linear-gradient(
      transparent 12px,
      var(--matterColorNegative) 12px,
      var(--matterColorNegative) 13px,
      transparent 13px
    );
  }
}
