@import '../../styles/propertySets.css';
@import '../../styles/customMediaQueries.css';


.root {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  background-color: var(--color-white-pure);
}

.darkSkin {
  background-color: var(--matterColor);
}

.tab {
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 24px;
  }
}

.tabContent {
  /** marketplaceLinkStyles */
 /* Position and dimensions */
    display: inline;
    margin: 0;
    padding: 0;

    /* Borders */
    border: none;

    /* Colors */
    color: var(--marketplaceColor);

    /* Text size should be inherited */
    text-decoration: none;

    &:hover {
      text-decoration: none;
      cursor: pointer;
    }
/** end */
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  /* Font */
  /** fave-copy-small-regular */  
 font-family: Proxima Nova;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.2px;
/** end */

  color: var(--fave-color-black-s300);
  
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: transparent;
  margin-right: 4px;
  transition: var(--transitionStyleButton);

  &:hover,
  &:focus {
    /** fave-copy-small-regular */  
   font-family: Proxima Nova;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.2px;
/** end */
    text-decoration: none;
    color: var(--fave-color-purple-s500);
  }

  @media (--viewportMedium) {
    border-bottom-width: 2px;
    padding: 16px 8px 16px 8px;
  }
}

.button {
  /* Override Chrome's border-radius which is visible when
   border is used as underline */
  border-radius: 0;
  /* Override Chrome's button outline */
  outline: 0;
}

.selectedTabContent {
  /** fave-copy-small-regular */  
   font-family: Proxima Nova;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.2px;
/** end */
  
  color: var(--fave-color-purple-s500);
}

.disabled {
  pointer-events: none;
  color: var(--matterColorAnti);
  text-decoration: none;
}

/* Dark skin */
.tabContentDarkSkin {
  color: var(--matterColorAnti);

  &:hover {
    color: var(--matterColorLight);
  }
}

.selectedTabContentDarkSkin {
  border-bottom-color: var(--matterColorLight);
  color: var(--matterColorLight);
}

.disabledDarkSkin {
  color: var(--matterColorDark);
  opacity: 0.3;
}

.notificationDot {
  /* Dimensions */
  width: 7px;
  height: 7px;
  border-radius: 50%;

  /* Style: red dot */
  background-color: var(--failColor);
}

.buttonContent {
  display: flex;
  flex-direction: row;
}
