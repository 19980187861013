@import '../../styles/propertySets.css';
@import '../../styles/customMediaQueries.css';
.root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qrCodeContainer {
  /* Frame 498 */

  display: none;
  /* Auto layout */

  flex-direction: row;
  align-items: flex-start;
  padding: 6px;
  gap: 6px;

  width: 84px;
  height: 96px;

  /* White */

  background: #ffffff;
  box-shadow: 0px 6.22764px 15.5691px rgba(0, 0, 0, 0.2);
  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  /* Black/50 */
  @media (--viewportMedium) {
    display: flex;
  }
}
.scrolLayerClasses {
  height: auto;
  justify-content: center;
}

.header {
  /** marketplaceH3FontStyles*/  
font-family: 'Greycliff';
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.5px;
/** end */
  text-align: left;

  @media (--viewportMedium) {
    text-align: center;
  }
}

.text {
  /** fave-copy-default-regular /*
  font-family: Proxima Nova;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
/** end */
  text-align: left;

  @media (--viewportMedium) {
    text-align: center;
  }
}

.storeContainer {
  display: flex;
  flex-direction: column;
  border: none;

  @media (--viewportMedium) {
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    border-left: 1px solid #e1e1e1;
    padding-left: 20px;
    margin-left: 20px;
  }
}

.imagesContainer {
  display: flex;
  height: 96px;
  align-items: center;
}
.margin {
  margin-bottom: 8px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}
