@import '../../styles/propertySets.css';
@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  border: var(--fave-card-border);
  background-color: var(--color-white-pure);
  padding: 24px;
  border-radius: var(--borderRadius);
  transition: all 0.15s;
  /* Remove link's hover effect */

  &:hover {
    text-decoration: none;
    transform: scale(1.01);
    border: var(--fave-card-border-hover);
    box-shadow: var(--fave-card-box-shadow);
  }
}

.imageContainer {
  position: relative;
  height: 100%;
  width: 100%;
}

.verifiedBadge {
  position: absolute;
  left: calc(100% - 120px);
  top: 12px;
  z-index: 999;
}
.verifiedBadgeGold {
  position: absolute;
  left: calc(100% - 150px);
  top: 12px;
  z-index: 999;
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--matterColorNegative); /* Loading BG color */
  border-radius: var(--borderRadius);

  @media (--viewportMedium) {
    &:hover {
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.rootForImage {
  border-radius: var(--borderRadius);
  border: var(--fave-card-border);
  border-radius: var(--borderRadius);
  width: 100%;
  overflow: hidden;
  height: 100%;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 16px 0 2px 0;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-right: 18px;
  padding: 4px 8px 4px 8px;
  background-color: white;
  border-radius: var(--borderRadius);
  width: fit-content;
  font-weight: bold;
}

.priceValue {
  /* Font */
  color: black;
  /**fave-copy-large-bold*/  
font-family: Proxima Nova;
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0em;
/** end */

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 8px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  /**marketplaceTinyFontStyles*/  
   font-family: var(--fontFamily);
    font-weight: var(--fontWeightRegular);
    font-size: 13px;
    line-height: 18px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 9.5px;
    margin-bottom: 8.5px;
    -webkit-font-smoothing: subpixel-antialiased;

    @media (--viewportMedium) {
      line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 10.5px;
      margin-bottom: 13.5px;
    }
/** end */
  color: var(--matterColor);
  display: inline-flex;
  align-items: center;
  margin-left: 8px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */

  /**fave-copy-large-bold*/  
font-family: Proxima Nova;
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0em;
/** end */
  font-weight: bold;
  line-height: 24px;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.authorInfo {
  /* Font */
  /** fave-copy-default-regular /*
  font-family: Proxima Nova;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
/** end */
  color: var(--matterColor);
  padding-top: 8px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.authorName {
  color: var(--fave-color-primary-default);
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.listingFandom {
  /** fave-copy-small-regular */  
 font-family: Proxima Nova;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.2px;
/** end */
  color: var(--fave-color-black-s200);
  margin-top: 8px;
  margin-bottom: 4px;
}
