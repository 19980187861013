@import '../../styles/customMediaQueries.css';

.productRow {
  padding-top: 4px;
  padding-bottom: 4px;
  display: flex;
}

.productTitle {
  /** fave-copy-small-regular */  
 font-family: Proxima Nova;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.2px;
/** end */
}
.spacer {
  flex-grow: 1;
}

.price {
  min-width: 50px;
  margin-left: 16px;
  height: 100%;
  display: flex;
  align-items: center;
}

.productPreviewContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (--viewportSmall) {
    flex-direction: row;
  }
}
.quantityDotContainer {
  position:relative;
}
.quantityDot {
  background-color: #000000;
  border-radius: 50%;
  min-width: 24px;
  height: 24px;
  width: fit-content;
  text-align: center;
  position: absolute;
  top: -6px;
  left: 40px;
  color: white;
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: center;
}

.productPreviewRow {
  padding: 8px;
  padding-left:0px;
  padding-right:0px;
  display: flex;
  align-items: center;
  width: 100%;

}

.productRowName {
  max-width: 600px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
}
.productPreviewImage {
  width: 50px;
  height: 50px;
  border-radius: var(--borderRadius);
  margin-right: 24px;
}
.quantityLabel {
  /** fave-copy-small-regular */  
   font-family: Proxima Nova;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.2px;
/** end */
  color: var(--fave-color-black-s300);
}
.quantityValue {
  /** fave-copy-small-regular */  
 font-family: Proxima Nova;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.2px;
/** end */
  color: var(--fave-color-black-s300);
}
.noDecorLink {
  text-decoration: none;
  color: black;
  &:hover {
    text-decoration: none;
    color: var(--fave-color-purple-s500);
  }
}

.openUrlButton {
  min-width: 160px;
  align-self: flex-end;
  margin-left: 0px;

  @media (--viewportSmall) {
    align-self: center;
    margin-left: 12px;
  }
}

.price {
  display: flex;
  height: 100%;
  justify-content: end;
  color: var(--fave-color-black-s300);
  /** fave-copy-default-regular /*
  font-family: Proxima Nova;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
/** end */
}
