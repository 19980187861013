.input {
  /* border-bottom-color: var(--attentionColor); */
}

.inputSuccess {
  border-color: var(--fave-color-primary-s500);
}

.inputError {
  border-color: var(--failColor);
}
