@import '../../styles/propertySets.css';

.root {
}

.content {
  display: flex;
  flex-direction: row;
}

.avatar {
  flex-shrink: 0;
  margin-right: 36px;
}

.info {
  flex: 1;
}

.heading {
  /** marketplaceH1FontStyles*/  
    font-family: 'Greycliff';
    font-size: 32px;
    font-weight: 700;
    line-height: 37px;
    letter-spacing: -1px;
/** end */
  margin: 20px 0 0 0;

  @media (--viewportMedium) {
    margin: 15px 0 0 0;
  }
}

.link {
  white-space: nowrap;
}

.mobileBio,
.desktopBio {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  margin: 18px 0 0 0;
  transition: all 1s ease-in;
  /**--marketplaceH4FontStyles*/  
  font-family: 'Greycliff';
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: -0.4000000059604645px;
/** end */

  @media (--viewportMedium) {
    margin: 0;
  }
}

.mobileBio {
  @media (--viewportMedium) {
    display: none;
  }
}

.desktopBio {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

.showMore {
  /** marketplaceLinkStyles */
 /* Position and dimensions */
    display: inline;
    margin: 0;
    padding: 0;

    /* Borders */
    border: none;

    /* Colors */
    color: var(--marketplaceColor);

    /* Text size should be inherited */
    text-decoration: none;

    &:hover {
      text-decoration: none;
      cursor: pointer;
    }
/** end */
  /**--marketplaceH4FontStyles*/  
  font-family: 'Greycliff';
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: -0.4000000059604645px;
/** end */
  margin: 0 0 0 5px;
}

.links {
  /**--marketplaceH4FontStyles*/  
  font-family: 'Greycliff';
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: -0.4000000059604645px;
/** end */
  margin-top: 13px;

  @media (--viewportMedium) {
    margin: 16px 0 0 0;
  }
}

.withBioMissingAbove {
  @media (--viewportMedium) {
    /* Fix baseline alignment if bio is missing from above */
    margin-top: 16px;
  }
}

.linkSeparator {
  margin: 0 10px;
  color: var(--marketplaceColor);
}

.headingRow {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }
}

.editProfileDesktop {
  /**--marketplaceH4FontStyles*/  
  font-family: 'Greycliff';
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: -0.4000000059604645px;
/** end */
  display: none;
  margin-bottom: 0;
  white-space: nowrap;

  @media (--viewportMedium) {
    display: inline;
  }
}

.editProfileMobile {
  /**--marketplaceH4FontStyles*/  
  font-family: 'Greycliff';
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: -0.4000000059604645px;
/** end */
  display: inline;

  @media (--viewportMedium) {
    display: none;
  }
}

.contact {
  /** marketplaceLinkStyles */
 /* Position and dimensions */
    display: inline;
    margin: 0;
    padding: 0;

    /* Borders */
    border: none;

    /* Colors */
    color: var(--marketplaceColor);

    /* Text size should be inherited */
    text-decoration: none;

    &:hover {
      text-decoration: none;
      cursor: pointer;
    }
/** end */
  /**--marketplaceH4FontStyles*/  
  font-family: 'Greycliff';
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: -0.4000000059604645px;
/** end */
  margin: 0;
}
