@import '../../styles/propertySets.css';
@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
}

.input {
  /* position: absolute; */
  opacity: 0;
  height: 0;
  width: 0;
color: var(--fave-color-primary-default);
}

.label {
  display: flex;
  align-items: center;
  background-color: var(--fave-color-purple-s50);
  margin-bottom: 6px;
  border: 1px solid var(--fave-color-purple-s50);
  border-radius: 15px;
  padding: 6px 12px;


}
.checkLabel{
  border: 1px solid var(--fave-color-primary-default);

}

.checkboxWrapper {
  height: 32px;
  margin-top: -1px;
  margin-right: 12px;
  align-self: baseline;
  left: 0px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  

  @media (--viewportMedium) {
    height: 24px;
  }
}

.checked {
  display: none;
  fill: var(--marketplaceColor);
}
.checkedSuccess {
  display: none;
  fill: var(--successColor);
}

.text {
  /** fave-copy-small-regular */  
   font-family: Proxima Nova;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.2px;
/** end */
  color:  var(--fave-color-primary-default);
}

.checkTxt {
  /** fave-copy-small-regular */  
   font-family: Proxima Nova;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.2px;
/** end */
    color:  var(--fave-color-primary-default);
    border: var(--fave-color-primary-default);

}
.textRoot {
  margin-top: -1px;
  margin-bottom: 1px;
  cursor: pointer;
}

.boxcont {
  box-sizing: border-box;

  width: 24px;
  height: 24px;

  /* Purple/50 */

  background: var(--fave-color-purple-s50);
  /* Purple/200 */

  border: 1px solid var(--fave-color-purple-s200);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
}

.boxChecked {
  background: linear-gradient(
    83.64deg,
    var(--fave-color-primary-s500) 10.03%,
    var(--fave-color-primary-s400) 89.97%
  );
  border-radius: 8px;
  box-sizing: content-box;
  border: none;
}
