@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.paymentHidden {
  display: none;
}

.paymentSuccess {
  margin-left: 4px;
  max-width: 200px;
  padding: 6px;
  background-color: var(--fave-color-white);
  color: var(--fave-color-success-s200);
  border-radius: 10px;
  text-align: center;
}

.paymentFailed {
  margin-left: 4px;
  max-width: 200px;
  padding: 6px;
  background-color: var(--fave-color-white);
  color: var(--fave-color-secondary-s900);
  border-radius: 10px;
  text-align: center;
}

.paymentRefunded {
  margin-left: 4px;
  max-width: 200px;
  padding: 6px;
  background-color: var(--fave-color-white);
  color: var(--fave-color-primary-default);
  border-radius: 10px;
  text-align: center;
}

.paymentPending {
  margin-left: 4px;
  max-width: 200px;
  padding: 6px;
  background-color: var(--fave-color-white);
  color: var(--fave-color-primary-default);
  border-radius: 10px;
  text-align: center;
}

.paymentStatus {
  /**fave-copy-xsmall-regular*/  
font-family: Proxima Nova;
    font-size: 12px;
    font-weight: 200;
    line-height: 15px;
    letter-spacing: 0.3px;
/** end */
  line-height: 18px;
  font-weight: var(--fontWeightMedium);
  letter-spacing: normal;

  margin-top: 6px;
  margin-bottom: 0px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: normal;
}
