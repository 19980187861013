@import '../../../styles/propertySets.css';
@import '../../../styles/customMediaQueries.css';

.buttonWithMargin {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quantityCapInfo {
  /** fave-copy-small-regular */  
 font-family: Proxima Nova;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.2px;
/** end */
  margin-top: 32px;
  text-align: center;
}

.quantityField {
  padding: 0 16px;
  margin-bottom: 16px;

  @media (--viewportLarge) {
    padding: 0;
  }
}

.marginLeft {
  margin-left: 4.87px;
}

.orderButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-bottom: 16px;
  }
}

.cursorNotAllowed {
  cursor: not-allowed;
}

.buttonContent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.orderButtonDisabled {
  background-image: linear-gradient(
    var(--fave-color-black-s75) 0%,
    var(--fave-color-black-s75) 100%
  );
  background-color: var(--fave-color-black-s75);
  color: var(--fave-color-black-s100);
}

.orderButtonDisabled:hover {
  background-color: var(--fave-color-black-s75);
  background-image: linear-gradient(
    var(--fave-color-black-s75) 0%,
    var(--fave-color-black-s75) 100%
  );
  color: var(--fave-color-black-s100);
}

.orderButtonDisabled:active {
  background-color: var(--fave-color-black-s75);
  background-image: linear-gradient(
    var(--fave-color-black-s75) 0%,
    var(--fave-color-black-s75) 100%
  );
  color: var(--fave-color-black-s100);
}

.deliveryField {
  display: none;
  padding: 0;
  margin-top: 24px;
  @media (--viewportLarge) {
    display: block;
  }
}

.singleDeliveryMethodSelected {
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.breakdownWrapper {
  padding: 0 16px;

  @media (--viewportLarge) {
    padding: 0;
    margin-top: 40px;
  }
}

.cartInfoWrapper {
  width: fit-content;
  margin: 48px auto 16px auto;
}

.cartInfo {
  /**marketplaceH5FontStyles */
    font-family: 'Greycliff';
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
/** end */
  border: 1px solid var(--fave-color-purple-s500);
  color: var(--fave-color-black-s400);
  width: fit-content;
  border-radius: 16px;
  padding: 4px 16px;
  margin-left: 16px;
}

.submitButton {
  padding: 0 16px;
  display: flex;

  @media (--viewportLarge) {
    padding: 0;
    flex-direction: column;
  }
}

.finePrint {
  /**marketplaceTinyFontStyles*/  
   font-family: var(--fontFamily);
    font-weight: var(--fontWeightRegular);
    font-size: 13px;
    line-height: 18px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 9.5px;
    margin-bottom: 8.5px;
    -webkit-font-smoothing: subpixel-antialiased;

    @media (--viewportMedium) {
      line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 10.5px;
      margin-bottom: 13.5px;
    }
/** end */
  text-align: center;
  visibility: hidden;

  @media (--viewportLarge) {
    visibility: visible;
  }
}

.error {
  /**--marketplaceH4FontStyles*/  
  font-family: 'Greycliff';
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: -0.4000000059604645px;
/** end */
  color: var(--failColor);

  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}
