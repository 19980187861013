@import '../../styles/propertySets.css';

.root {
  /* Adding empty .root class to enforce className overwrite */
}

/* Title of the modal */
.modalTitle {
  /**marketplaceModalTitleStyles*/  
   font-weight: var(--fontWeightBold);
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.5px;
    margin: 0;
    font-weight: var(--fontWeightBold);

    @media (--viewportMedium) {
      font-weight: var(--fontWeightBold);
      line-height: 40px;
      letter-spacing: -0.9px;
      margin: 0;
    }
/** end */;
}

/* Paragraph for the Modal */
.modalMessage {
  /**marketplaceModalParagraphStyles*/  
    font-weight: var(--fontWeightRegular);
    font-size: 16px;
    line-height: 24px;
    margin: 18px 0 0 0;

    @media (--viewportMedium) {
      font-weight: var(--fontWeightRegular);
      font-size: 20px;
      line-height: 32px;
      letter-spacing: -0.11px;
      margin: 24px 0 0 0;
    }
/** end */;
}

/* Make the email pop */
.email {
  font-weight: var(--fontWeightHighlightEmail);
}

.helperLink {
  /** marketplaceLinkStyles */
 /* Position and dimensions */
    display: inline;
    margin: 0;
    padding: 0;

    /* Borders */
    border: none;

    /* Colors */
    color: var(--marketplaceColor);

    /* Text size should be inherited */
    text-decoration: none;

    &:hover {
      text-decoration: none;
      cursor: pointer;
    }
/** end */
  /**marketplaceModalHelperLink*/  
     /**marketplaceTinyFontStyles*/  
   font-family: var(--fontFamily);
    font-weight: var(--fontWeightRegular);
    font-size: 13px;
    line-height: 18px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 9.5px;
    margin-bottom: 8.5px;
    -webkit-font-smoothing: subpixel-antialiased;

    @media (--viewportMedium) {
      line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 10.5px;
      margin-bottom: 13.5px;
    }
/** end */
    color: var(--matterColor);
    margin: 0;

    @media (--viewportMedium) {
      margin: 0;
    }
/** end */
}

.helperText {
  /**marketplaceModalHelperText*/  
  /**marketplaceTinyFontStyles*/  
   font-family: var(--fontFamily);
    font-weight: var(--fontWeightRegular);
    font-size: 13px;
    line-height: 18px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 9.5px;
    margin-bottom: 8.5px;
    -webkit-font-smoothing: subpixel-antialiased;

    @media (--viewportMedium) {
      line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 10.5px;
      margin-bottom: 13.5px;
    }
/** end */
    color: var(--matterColorAnti);
    margin: 0;

    @media (--viewportMedium) {
      margin: 0;
    }
/** end */
}

.error {
  /**marketplaceModalErrorStyles*/  
   /**--marketplaceH4FontStyles*/  
  font-family: 'Greycliff';
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: -0.4000000059604645px;
/** end */
    margin-top: 24px;
    color: var(--failColor);
/** end */
}

.bottomWrapper {
  /**marketplaceModalBottomWrapper*/  
   text-align: center;
    margin-top: 60px;
    align-self: stretch;

    @media (--viewportMedium) {
      margin-top: 96px;
    }
/** end */

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

.modalIcon {
  /**marketplaceModalIconStyles*/  
      height: 48px;
    margin-bottom: 12px;

    @media (--viewportMedium) {
      height: 64px;
      margin-bottom: 23px;
    }
/** end */
}

.reminderModalLinkButton {
  /**marketplaceButtonStylesPrimary*/  
    font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
    font-weight: var(--fontWeightSemiBold);
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 24px;

    /* Dimensions */
    display: block;
    width: 100%;
    min-height: 40px;
    margin: 0;

    /* Padding is only for <a> elements where button styles are applied,
		buttons elements should have zero padding */
    padding: 5px 0 5px 0;

    @media (--viewportMedium) {
      padding: 10px 0 10px 0;
    }

    /* Borders */
    border: none;
    border-radius: var(--borderRadius);

    /* Colors */
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);

    /* Text */
    text-align: center;
    text-decoration: none;

    /* Effects */
    transition: all var(--transitionStyleButton);
    cursor: pointer;

    &:hover,
    &:focus {
      outline: none;
      background-color: var(--marketplaceColorDark);
      text-decoration: none;
    }
    &:disabled {
      background-color: var(--matterColorNegative);
      color: var(--matterColorLight);
      cursor: not-allowed;
      box-shadow: none;
    }

    @media (--viewportMedium) {
      min-height: 40px;
    }
    
    background-color: black;
    background-image: none;
    color: var(--color-white-pure);

    &:hover,
    &:focus {
      background-image: none;
      background-color: black;
    }
    &:disabled {
      background-image: none;
      background-color: var(--fave-color-border);
      color: var(--color-white-pure);
    }
/** end */;
}
