@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

/* 404 page */

.root {
  /* Expand to the available space */
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  max-width: 587px;
  width: 80%;
  margin-top: 60px;
  margin-bottom: 60px;

  @media (--viewportMedium) {
    margin-top: 10vh;
  }
}

.number {
  /** marketplaceHeroTitleFontStyles */
  font-weight: var(--fontWeightBold);
    font-size: 36px;
    line-height: 36px;
    letter-spacing: -0.7px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 25px;
    margin-bottom: 14px;

    @media (--viewportMedium) {
      font-size: 64px;
      line-height: 64px;
      letter-spacing: -1.5px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 25px;
      margin-bottom: 23px;
    }
/** end */
  text-align: center;
  color: var(--marketplaceColor);
  margin: 1px 0 0 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.heading {
  /**marketplaceModalTitleStyles*/  
   font-weight: var(--fontWeightBold);
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.5px;
    margin: 0;
    font-weight: var(--fontWeightBold);

    @media (--viewportMedium) {
      font-weight: var(--fontWeightBold);
      line-height: 40px;
      letter-spacing: -0.9px;
      margin: 0;
    }
/** end */;
  text-align: center;
  margin-top: 16px;

  @media (--viewportMedium) {
    margin-top: 22px;
  }
}

.description {
  text-align: center;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 24px;
    margin-bottom: 0;
  }
}

.searchForm {
  max-width: 408px;
  margin: 62px auto 0 auto;

  background-color: var(--matterColorLight);
  border-bottom: 4px solid var(--marketplaceColor);
  box-shadow: var(--boxShadowNotFoundPageSearch);

  @media (--viewportMedium) {
    margin-top: 64px;
  }
}
