@import '../../styles/faveStyles.css';

.ShoppingCart {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}
.ShoppingCartLink {
  height: 34px;
  width: 89.5px;
  border: 1px solid var(--fave-color-primary-default);
  margin-top: calc((72px - 34px) / 2);
  margin-bottom: calc((72px - 34px) / 2);
  margin-right: 37.5px;
  margin-left: 12px;
  border-radius: 8px;
  padding: 8px 4px;
  position: relative;
}

.Price {
  font-weight: 700;
  font-size: 14px;
  line-height: 125%;
  color: var(--fave-color-primary-default);
}

.BagIcon {
  color: white;
  height: 35px;
}

.countCircle {
  position: absolute;
  height: 34px;
  right: -20%;
  top: calc(50% - 23px / 2 - 7.5px);
  color: white;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    180deg,
    var(--fave-color-error-s600) 0%,
    var(--fave-color-error-s300) 100%
  );
  font-weight: 700;
  font-size: 12px;
  line-height: 125%;
}
