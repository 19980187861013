@import '../../styles/faveStyles.css';

.root {
  background: linear-gradient(
    90deg,
    var(--fave-color-primary-s500) 0%,
    var(--fave-color-primary-s400) 100%
  );
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  min-height: 32px;
  border-radius: 20px;
  padding: 0px 8px;
}

.text {
  color: white;
  //styleName: Copy/Xsmall/Bold;
  font-family: var(--fave-copy-xsmall-bold);
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.30000001192092896px;
  text-align: left;
  margin-left: 3px;
}
.textGold {
  color: var(--fave-color-black-s900) !important;
}
.goldPill {
  color: var(--fave-color-blue-s900);
  background: linear-gradient(
    90deg,
    var(--fave-color-secondary-s500) 0%,
    var(--fave-color-secondary-s400) 100%
  );
}
.tick {
  position: absolute;
}

.star {
  position: absolute;
}

.svgContainerSection {
  height: 20px;
  width: 20px;
  position: relative;
}
