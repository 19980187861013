@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.tab {
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }
}

.link {
  display: inline-block;
  white-space: nowrap;

  /* Font */
  /**fave-copy-xsmall-bold*/  
    font-family: Proxima Nova;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0.3px;
/** end */;

  color: var(--fave-color-black-s500);
  padding-bottom: 10px;
  padding-top: 10px;

  /* push tabs against bottom of tab bar */
  margin-top: auto;

  &:hover {
    text-decoration: none;
    color: var(--fave-color-purple-s700);
  }
}

.stepItem {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.stepSpacer {
  width: 16px;
  height: 3px;
  background-color: var(--fave-color-black-s50);
  border-radius: 1px;
  margin-right: 8px;
}

.stepCounter {
  /**fave-copy-xsmall-bold*/  
    font-family: Proxima Nova;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0.3px;
/** end */;
  background-color: var(--fave-color-white);
  color: black;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectedLink > .stepCounter {
  /**fave-copy-xsmall-bold*/  
    font-family: Proxima Nova;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0.3px;
/** end */;
  background-color: var(--fave-color-purple-s100);
  color: var(--fave-color-purple-s500);
  margin-right: 8px;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectedLink {
  color: var(--fave-color-purple-s500);
}

.disabled {
  pointer-events: none;
  color: var(--fave-color-black-s50);
  text-decoration: none;
}

.disabled > .stepCounter {
  color: var(--fave-color-black-s50);
}
