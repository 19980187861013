@import '../../../styles/propertySets.css';

.root {
  /**marketplaceModalFormRootStyles*/
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  height: 100%;
  /** end */

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}
.hidden {
  display: none;
}
.recaptchaContainer {
  margin-top: 24px;
}

.password {
  /**marketplaceModalPasswordMargins*/
  /* Leave space between the input and the button below when the
    viewport height is small */
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
  /** end */
}

.bottomWrapper {
  /**marketplaceModalBottomWrapper*/
  text-align: center;
  margin-top: 60px;
  align-self: stretch;

  @media (--viewportMedium) {
    margin-top: 96px;
  }
  /** end */
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  /**marketplaceModalBottomWrapperText*/
  margin-top: -24px;
  padding-bottom: 8px;

  @media (--viewportMedium) {
    padding-bottom: 0;
  }
  /** end */
}

.recoveryLink {
  /**marketplaceModalHelperLink*/
  /**marketplaceTinyFontStyles*/
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightRegular);
  font-size: 13px;
  line-height: 18px;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 9.5px;
  margin-bottom: 8.5px;
  -webkit-font-smoothing: subpixel-antialiased;

  @media (--viewportMedium) {
    line-height: 16px;
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 10.5px;
    margin-bottom: 13.5px;
  }
  /** end */
  color: var(--matterColor);
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
  /** end */
}

.recoveryLinkInfo {
  /**marketplaceModalHelperText*/
  /**marketplaceTinyFontStyles*/
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightRegular);
  font-size: 13px;
  line-height: 18px;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 9.5px;
  margin-bottom: 8.5px;
  -webkit-font-smoothing: subpixel-antialiased;

  @media (--viewportMedium) {
    line-height: 16px;
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 10.5px;
    margin-bottom: 13.5px;
  }
  /** end */
  color: var(--matterColorAnti);
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
  /** end */
}
