@import '../../styles/propertySets.css';

.root {
  margin-top: 24px;
}

@media (--viewportMedium) {
  .root {
    margin-top: 48px;
  }
}

.disabled {
  opacity: 0.5;
}

.sectionContainer {
  margin-bottom: 35px;

  @media (--viewportMedium) {
    margin-bottom: 56px;
  }
}

.formRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
}

.radioButtonRow {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 24px;
  white-space: nowrap;
}

.radioButtonRow > :first-child {
  margin-right: 36px;
}

.selectCountry {
  margin-bottom: 24px;
}

.error {
  /**marketplaceModalErrorStyles*/  
   /**--marketplaceH4FontStyles*/  
  font-family: 'Greycliff';
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: -0.4000000059604645px;
/** end */
    margin-top: 24px;
    color: var(--failColor);
/** end */
}

.termsText {
  /**marketplaceModalHelperText*/  
  /**marketplaceTinyFontStyles*/  
   font-family: var(--fontFamily);
    font-weight: var(--fontWeightRegular);
    font-size: 13px;
    line-height: 18px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 9.5px;
    margin-bottom: 8.5px;
    -webkit-font-smoothing: subpixel-antialiased;

    @media (--viewportMedium) {
      line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 10.5px;
      margin-bottom: 13.5px;
    }
/** end */
    color: var(--matterColorAnti);
    margin: 0;

    @media (--viewportMedium) {
      margin: 0;
    }
/** end */
  margin-bottom: 12px;
  text-align: center;

  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.termsLink {
  /**marketplaceModalHelperLink*/  
     /**marketplaceTinyFontStyles*/  
   font-family: var(--fontFamily);
    font-weight: var(--fontWeightRegular);
    font-size: 13px;
    line-height: 18px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 9.5px;
    margin-bottom: 8.5px;
    -webkit-font-smoothing: subpixel-antialiased;

    @media (--viewportMedium) {
      line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 10.5px;
      margin-bottom: 13.5px;
    }
/** end */
    color: var(--matterColor);
    margin: 0;

    @media (--viewportMedium) {
      margin: 0;
    }
/** end */

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.bankDetailsStripeField p {
  /**--marketplaceH4FontStyles*/  
  font-family: 'Greycliff';
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: -0.4000000059604645px;
/** end */
}

.missingStripeKey {
  color: var(--failColor);
}

.accountInformationTitle {
  /** marketplaceH3FontStyles*/  
font-family: 'Greycliff';
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.5px;
/** end */
  margin: 0;
  padding-top: 5px;
  padding-bottom: 1px;
}

@media (--viewportMedium) {
  .accountInformationTitle {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.savedCountry {
  padding-top: 6px;
  padding-bottom: 0px;
}
@media (--viewportMedium) {
  .savedCountry {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.savedInformation {
  margin-bottom: 24px;
}

.savedBankAccount {
  width: 100%;
  border-bottom: 2px solid var(--successColor);
  margin-bottom: 24px;
  padding: 4px 0 10px 0;
  color: var(--matterColor);
  text-align: left;

  &:hover {
    cursor: text;
    text-decoration: none;
    border-color: var(--matterColor);
  }

  &:focus {
    outline: none;
    border-color: var(--matterColor);
  }
}
