@import './../../styles/propertySets.css';

.root {
}

.input {
  /* border-bottom-color: var(--attentionColor); */
  &:focus {
    box-shadow: 0px 0px 13px -6px #B196FF, inset 0px 0px 13px -6px #B196FF;

  }
}

.inputSuccess {
  border-color: var(--fave-color-primary-s500);
  /* border-bottom-color: var(--successColor); */
  &:disabled {
    color: var(--fave-color-black-s100);
    border-color: var(--fave-color-black-s50);
  }
}

.inputError {
  border-color: var(--failColor);
}

.textarea {
  background-color: var(--color-white-pure);

  padding: 8px 16px 0px 16px;

  /* Borders */
  border: 1px solid var(--fave-color-black-s50);
  border-radius: var(--borderRadius);

  transition: border-color var(--transitionStyle);

  &:hover,
  &:focus {

    outline: none;
  }

  &:disabled {
    color: var(--fave-color-input);
    border-color: var(--fave-color-input);
  }
}

textarea::placeholder {
  padding-top: 8px;
  color: var(--fave-color-black-s100);
  /** fave-copy-default-regular /*
  font-family: Proxima Nova;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
/** end */
}
