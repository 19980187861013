@import '../../styles/propertySets.css';
@import '../../styles/customMediaQueries.css';
.root {
  width: 100%;

  /* Top Bar's drop shadow should always be visible */
  z-index: var(--zIndexTopbar);
  position: relative;
}

.container {
  /* Size */
  width: 100%;
  height: var(--topbarHeight);
background-color: white;

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /* fill */
  background-color: var(--matterColorLight);

  /* shadows */
  box-shadow: var(--boxShadow);

  @media (--viewportMedium) {
    display: none;
  }
}

.home {
  display: flex;
  height: var(--topbarHeight);
  /* Calculate vertical padding. Something like: (60px - 25px)/2 */
  padding: calc((var(--topbarHeight) - var(--Topbar_logoHeight)) / 2) 24px;
}

.logo {
  object-fit: contain;
}

.logoutButton {
  margin: 0;
}

/* Child component's size (and positioning) should be given here */
.menu,
.searchMenu {
  /* Font */
  /**marketplaceH5FontStyles */
    font-family: 'Greycliff';
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
/** end */
  color: var(--matterColor);

  /* Layout */
  display: flex;
  width: 66px;
  margin: 0;
  border-width: 0px;
  background-color: transparent;

  /* Hovers */
  &:enabled {
    cursor: pointer;
  }
  &:enabled:hover,
  &:enabled:active {
    background-color: transparent;
    box-shadow: none;
  }
  &:disabled {
    background-color: transparent;
    cursor: auto;
  }
}

.menu {
  padding: 15px 24px;
  position: relative;
}
.searchMenu {
  padding: 12px 24px;
}

.menuIcon,
.searchMenuIcon {
  margin: 9px 0;
}

.notificationDot {
  /* Dimensions */
  width: 12px;
  height: 12px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: calc(50% - 23px / 2 - 7.5px);
  right: 17px;
  
  /* Style: red dot with white border */
  background-color: var(--failColor);
  border: 2px solid var(--matterColorLight);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.desktop {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

/* Icon component styles */
.rootMenuIcon {
  fill: var(--matterColor);
}
.rootSearchIcon {
  stroke: var(--matterColor);
}

.searchContainer {
  position: relative;
  height: 100%;
  margin-top: 94px;
}

.mobileHelp {
  color: var(--matterColorAnti);
  margin: 0 24px;

  /* Absolute position to avoid affecting the layout of the autocomplete
   predictions list */
  position: absolute;
  top: 73px;
}

/* ================ Modal ================ */

.modalContainer {
  /**marketplaceModalInMobileBaseStyles*/  
    flex-grow: 1;

    /* Create context so the close button can be positioned within it */
    position: relative;

    /* Display and dimensions */
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 0;

    background-color: var(--matterColorLight);
    border-radius: var(--borderRadius);

    @media (--viewportMedium) {
      flex-basis: 480px;
      flex-grow: 0;
      height: 100%;
      padding: 0;
      background-color: transparent;
      border-radius: 0;
    }
/** end */
  padding: 0;

  @media (--viewportMedium) {
    flex-basis: 576px;
  }
}

.missingInformationModal {
  /**marketplaceModalBaseStyles*/  
     flex-grow: 1;

    /* Create context so the close button can be positioned within it */
    position: relative;

    /* Display and dimensions */
    display: flex;
    flex-direction: column;
    padding: var(--modalPadding);

    background-color: var(--matterColorLight);
    border-radius: var(--borderRadius);
    border-bottom: none;

    @media (--viewportMedium) {
      flex-basis: 480px;
      flex-grow: 0;
      /* min-height: 568px; */
      padding: var(--modalPaddingMedium);
      margin-top: 12.5vh;
      margin-bottom: 12.5vh;
    }
/** end */
  padding-top: 70px;
}

/* Title of the modal */
.modalTitle {
  /**marketplaceModalTitleStyles*/  
   font-weight: var(--fontWeightBold);
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.5px;
    margin: 0;
    font-weight: var(--fontWeightBold);

    @media (--viewportMedium) {
      font-weight: var(--fontWeightBold);
      line-height: 40px;
      letter-spacing: -0.9px;
      margin: 0;
    }
/** end */;
}

/* Paragraph for the Modal */
.modalMessage {
  /**marketplaceModalParagraphStyles*/  
    font-weight: var(--fontWeightRegular);
    font-size: 16px;
    line-height: 24px;
    margin: 18px 0 0 0;

    @media (--viewportMedium) {
      font-weight: var(--fontWeightRegular);
      font-size: 20px;
      line-height: 32px;
      letter-spacing: -0.11px;
      margin: 24px 0 0 0;
    }
/** end */;
}

/* Make the email pop */
.email {
  font-weight: var(--fontWeightHighlightEmail);
}

.helperLink {
  /**marketplaceModalHelperLink*/  
     /**marketplaceTinyFontStyles*/  
   font-family: var(--fontFamily);
    font-weight: var(--fontWeightRegular);
    font-size: 13px;
    line-height: 18px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 9.5px;
    margin-bottom: 8.5px;
    -webkit-font-smoothing: subpixel-antialiased;

    @media (--viewportMedium) {
      line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 10.5px;
      margin-bottom: 13.5px;
    }
/** end */
    color: var(--matterColor);
    margin: 0;

    @media (--viewportMedium) {
      margin: 0;
    }
/** end */
}

.helperText {
  /**marketplaceModalHelperText*/  
  /**marketplaceTinyFontStyles*/  
   font-family: var(--fontFamily);
    font-weight: var(--fontWeightRegular);
    font-size: 13px;
    line-height: 18px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 9.5px;
    margin-bottom: 8.5px;
    -webkit-font-smoothing: subpixel-antialiased;

    @media (--viewportMedium) {
      line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 10.5px;
      margin-bottom: 13.5px;
    }
/** end */
    color: var(--matterColorAnti);
    margin: 0;

    @media (--viewportMedium) {
      margin: 0;
    }
/** end */
}

.error {
  /**marketplaceModalErrorStyles*/  
   /**--marketplaceH4FontStyles*/  
  font-family: 'Greycliff';
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: -0.4000000059604645px;
/** end */
    margin-top: 24px;
    color: var(--failColor);
/** end */
}

.bottomWrapper {
  /**marketplaceModalBottomWrapper*/  
   text-align: center;
    margin-top: 60px;
    align-self: stretch;

    @media (--viewportMedium) {
      margin-top: 96px;
    }
/** end */

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

.modalIcon {
  /**marketplaceModalIconStyles*/  
      height: 48px;
    margin-bottom: 12px;

    @media (--viewportMedium) {
      height: 64px;
      margin-bottom: 23px;
    }
/** end */
}

.reminderModalLinkButton {
  /**marketplaceButtonStylesPrimary*/  
    font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
    font-weight: var(--fontWeightSemiBold);
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 24px;

    /* Dimensions */
    display: block;
    width: 100%;
    min-height: 40px;
    margin: 0;

    /* Padding is only for <a> elements where button styles are applied,
		buttons elements should have zero padding */
    padding: 5px 0 5px 0;

    @media (--viewportMedium) {
      padding: 10px 0 10px 0;
    }

    /* Borders */
    border: none;
    border-radius: var(--borderRadius);

    /* Colors */
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);

    /* Text */
    text-align: center;
    text-decoration: none;

    /* Effects */
    transition: all var(--transitionStyleButton);
    cursor: pointer;

    &:hover,
    &:focus {
      outline: none;
      background-color: var(--marketplaceColorDark);
      text-decoration: none;
    }
    &:disabled {
      background-color: var(--matterColorNegative);
      color: var(--matterColorLight);
      cursor: not-allowed;
      box-shadow: none;
    }

    @media (--viewportMedium) {
      min-height: 40px;
    }
    
    background-color: black;
    background-image: none;
    color: var(--color-white-pure);

    &:hover,
    &:focus {
      background-image: none;
      background-color: black;
    }
    &:disabled {
      background-image: none;
      background-color: var(--fave-color-border);
      color: var(--color-white-pure);
    }
/** end */;
}

.genericError {
  position: fixed;
  top: calc(var(--topbarHeight) + 15px);

  /* Place enough to the right to hide the error */
  right: -355px;
  opacity: 0;

  max-width: 340px;
  margin: 0 15px;

  /* Animate error from the right */
  transition: all ease-out 0.2s;
  transition-delay: 1s;

  /* Bleed the bg color from the bottom */
  padding-bottom: 4px;
  background-color: var(--marketplaceColor);
  border-radius: 4px;

  /* Place on top of everything */
  z-index: var(--zIndexGenericError);

  @media (--viewportMedium) {
    top: calc(var(--topbarHeightDesktop) + 15px);
  }
}

.genericErrorVisible {
  right: 0;
  opacity: 1;
}

.genericErrorContent {
  padding: 16px 22px;
  border-radius: 4px;
  background-color: var(--marketplaceColorLight);
  border-radius: 4px;
}

.genericErrorText {
  /**marketplaceH5FontStyles */
    font-family: 'Greycliff';
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
/** end */
  font-weight: var(--fontWeightSemiBold);
  margin: 0;
  color: var(--matterColorLight);

  @media (--viewportMedium) {
    margin: 0;
  }
}
