@import '../../styles/propertySets.css';

.root {
  /**fave-copy-xsmall-regular*/  
font-family: Proxima Nova;
    font-size: 12px;
    font-weight: 200;
    line-height: 15px;
    letter-spacing: 0.3px;
/** end */
  margin-top: 0px;
  margin-bottom: 12px;
  padding-left: 4px;
  margin-left: 16px;
  color: var(--fave-color-error-s900);
  overflow: hidden;
  display: flex;
  align-items: center;

  @media (--viewportMedium) {
    margin-top: 6px;
    margin-bottom: 10px;
  }
}

.icon {
  margin-right: 4px;
}
