@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  justify-content: flex-start;
  height: 60px;
  align-items: flex-start;
  padding: 0px 10px 0 10px;
  flex-flow: row;
  height: 60px;
  padding: 0 ;
   

  @media (--viewportMedium) {
    display: flex;
    justify-content: flex-start;
  
    align-items: flex-start;
    padding: 0px;
    flex-flow: column;
        height: fit-content;
  
  }

 
}

.tab {
  display: flex;
  align-items: flex-end;
  height: 100%;
  margin-left: 8px;
 

  @media (--viewportMedium) {
    min-width: 200px;
  }

  @media (--viewportLarge) {
    min-width: 200px;
  }
  /* &:first-child {
    margin-left: 0;
  } */
}
