@import '../.../../../styles/propertySets.css';

.variants {
  display: flex;
}

.variantTitle {
  overflow: hidden;
  text-overflow: ellipsis;
  /** fave-copy-small-regular */  
   font-family: Proxima Nova;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.2px;
/** end */
  color: var(--fave-color-black-s300);
}

.variantInfo {
  /** fave-copy-small-regular */  
 font-family: Proxima Nova;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.2px;
/** end */
  margin-right: 8px;
  color: var(--fave-color-black-s300);
}
