.root {
}

.select {
  color: var(--matterColorAnti);
  padding-right: 32px;

  &:focus {
    box-shadow: 0px 0px 13px -6px #B196FF, inset 0px 0px 13px -6px #B196FF;

  }

  & > option {
    color: var(--matterColor);
  }
  & > option:disabled {
    color: var(--matterColorAnti);
  }
}

.selectSuccess {
  color: var(--matterColor);
  font-weight: bold;
  border-color: var(--fave-color-primary-s500);
  /* border-bottom-color: var(--successColor); */
}

.selectSuccess:disabled {
  color: var(--matterColorAnti);
  font-weight: normal;
  /* border-bottom-color: var(--successColor); */
}

.selectError {
  border-color: var(--failColor);
}
